@keyframes custom-fadein {
    0% {
        opacity: 1.0;
    }

    100% {
        opacity: 0;
    }
}

.highlighted-row {
    position: relative; /* Important for overlay positioning */
}

    .highlighted-row::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation-name: custom-fadein;
        animation-duration: 2s; /* (Should be same as value in setTimeout) */
        animation-fill-mode: forwards;
        background-color: #7fbae3; /* This is LT_JOURNEY50 */
        z-index: 1; /* Ensure overlay is above row content */
    }
